<template>
    <div class="articles-content">
        <div class="articles-item">
            <div class="articles-name">分类阅读：</div>
            <div class="articles-list">
                <div class="item" :class="{current:currentTopic === articlesIndex}" v-for="(articlesItem,articlesIndex) in articlesClassification" :key="articlesIndex" @click="chooseTopic(articlesIndex)">{{articlesItem}}</div>
            </div>
        </div>
        <div class="articles-info" v-if="articlesList.length > 0">
            <div class="top-animate" v-for="(item,index) in articlesList" :key="index" >
                <a  target="_blank" :href="item.link">
                    <div class="articles-img">
                        <img :src="item.img" alt="" v-show="item.img != null">
                        <img src="../../../assets/img/image/articles.png" alt="" v-show="item.img == null">
                    </div>
                    <div class="info-title">
                        <p class="text">{{item.title}}</p>
                        <p class="time">发布时间：{{item.time}}</p>
                    </div>
                </a>
            </div>
        </div>
        <div v-else class="null-img">
            <img src="../../../assets/img/image/null-data.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "HotArticles",
        data(){
            return{
                articlesList:[],
                currentTopic:0,
                articlesClassification:['国内焦点','国际焦点','军事焦点','财经焦点','互联网焦点','房产焦点','汽车焦点','体育焦点'],
                class_name:'国内焦点'

            }
        },
        mounted() {
            this.viewList('国内焦点');
        },
        methods:{
            // 分类阅读
            chooseTopic(articlesIndex) {
                this.currentTopic = articlesIndex;
                this.viewList(this.articlesClassification[articlesIndex]);
            },
            viewList(class_name) {
                let param  ={
                    class_name:class_name
                };
                this.$httpStudent.axiosGetBy(this.$api.hotNewsList, param, res => {
                    if (res.code === 200){
                        this.articlesList = res.data;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .articles-content{
        display: flex;
        /*width: calc(100% - 280px);*/
        /*padding: 20px 20px 0 0;*/
        flex-direction: column;
        height: 100%;
        .articles-item{
            background: #fff;
            border-radius: 8px;
            display: flex;
            flex-wrap: wrap;
            padding: 20px 20px 10px;
            .articles-name {
                line-height:30px;
            }
            .articles-list {
                display: flex;
                width: 1%;
                flex: 1;
                .item {
                    line-height: 30px;
                    padding: 0 10px;
                    margin: 0 0 10px 10px;
                    color: #666;
                    cursor: pointer;
                    &.current,&:hover{
                        background: #BEC3F1;
                        color: #2338E6;
                        border-radius: 4px;
                    }
                }
            }
        }
        .articles-info{
            margin-right: -20px;
            display: flex;
            flex-wrap: wrap;
            .top-animate{
                width: calc(25% - 20px);
                margin: 20px 20px 0 0;
                border-radius: 8px;
                overflow: hidden;
                .articles-img{
                    width: 100%;
                    height: 0;
                    padding-bottom: 57%;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    background: #fff;
                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                a{
                    color: #333;
                }

                .info-title{
                    background: #fff;
                    padding-bottom: 16px;
                    p{
                        padding:16px 16px 0;
                        margin: 0;
                    }
                    .text{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display:block;
                        -webkit-box-orient: vertical;
                        max-width: 370px;
                        max-height: 39px;
                        white-space: nowrap;
                    }
                }
            }
        }
        .null-img{
            height:650px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>